import store from "@/store";
'use strict'
// import { id } from 'date-fns/locale'
import http, { fetchApi } from "@/utils/axios";
let enterprise = '/api/enterprise'; // 企业
let user = '/api/dscloud-ies-user'; // 企业
let RoleId = store.state.common.currentMenuRoleId 
let platform= '/api/dscloud-ies-platform'
let URL = {
  // 获取融资类型
  GET_TRADE_INFO: enterprise + '/trade_info.json',
  // 获取可入住项目
  GET_ALLOW_JOIN_PROJECT: user + '/user/getProjectIdByUserId',
  // 获取企服专业列表
  GET_COMMISSIONER_LIST_BY_PROJECT_ID: enterprise + '/iyou/enterprise/getCommissionerByProjectId',
  // 获取角色列表
  GET_ROLE_LIST: user + '/role/findRoleByRoleType',
  // 添加员工
  ADD_STAFF: enterprise + '/staff/addStaff',
  // 编辑员工
  EDIT_STAFF: enterprise + '/staff/updateStaff',
  // 删除列表查询
  GET_DELECT_LIST: enterprise + '/staff/delete/pageList',
  // 下载模板
  TEMPLATE_DELETE_EXPORT: enterprise + '/staff/delete/templateExport',
  // 批量删除
  BATCH_DELETE: enterprise + '/staff/delete/batchDeletedStaff',
  // 批量删除
  GET_DOWNLOAD_RESULE: enterprise + '/staff/delete/downUploadResult',
  // 删除员工/移除员工
  DELETE_STAFF: enterprise + '/staff/deleteStaff',
  // 获取员工列表
  GET_STAFF_LIST: enterprise + '/iyou/staff/findStaffListNew',
  AUTH_PROJECT_LIST: enterprise + '/enterprise/findProject',
  // 企业列表获取接口
  enterpriseNamePlanB: enterprise + '/enterprise/findEnterpriseList',
  // 企业列表-入驻状态
  ENTERPRISE_SETTLE_LIST: enterprise + '/enterprise/settle/list',
  // 获取企服项目
  GET_SHUNYI_ALL_PROJECTLIST: '/api/project/saas/project/userRole/treeProjectList',
  // 获取行业分类树形列表
  ADDINDUSTRYLIST: enterprise + '/enterprise/industry/add',
  GETINDUSTRYLIST: enterprise + '/iyou/enterprise/industry/tree',
  DELETEINDUSTRYLIST: enterprise + '/enterprise/industry/',
  GETALLINDUSTRYLIST: enterprise + '/enterprise/industry/list',
  GETALLINDUSTRYINFO: enterprise + '/enterprise/industry/',
  UPDATEINDUSTRYLIST: enterprise + '/enterprise/industry/update',
  //  上传Excel文件批量导入企查查同步企业UsCode
  POST_IMPORTENTUSCODE: enterprise + '/iyou/enterprise/otherInfo/import/entUsCode',
  //  企查查错误UsCode列表
  POST_GETERRORUSCODELIST: enterprise + '/iyou/enterprise/otherInfo/error-usCode/list',
  //  导出Excel错误企业UsCode数据
  POST_DOWNERROREXCEL: enterprise + '/enterprise/otherInfo/down/error-excel',
  //  查询企查查同步企业其他信息
  GET_OTHERINFODETAIL: enterprise + '/enterprise/otherInfo/detail',
  //  手动触发批量更新数据
  GET_UPADTEINFOS: enterprise + '/enterprise/otherInfo/qichacha-binding/update-infos',
  //  手动触发单条更新数据
  POST_UPADTEINFO: enterprise + '/enterprise/otherInfo/qichacha-binding/update-info',
  //  修改绑定企查查企业 启用/禁用
  POST_UPADTEBINDINGSTATE: enterprise + '/enterprise/otherInfo/qichacha-binding/update-state',
  //  添加绑定企查查企业信息
  POST_ADDBINDING: enterprise + '/iyou/enterprise/otherInfo/qichacha-binding/add',
  //  绑定企查查企业列表
  POST_GETBINDINGLIST: enterprise + '/iyou/enterprise/otherInfo/qichacha-binding/list',
  //  新增关联企业
  ADD_GROUPADD: enterprise + '/enterprise/group/add',
  //  获取关联企业下拉
  GET_GROUPDROPDOWN: enterprise + '/enterprise/group/dropDown',
  //  获取关联企业详情
  GET_GROUP: enterprise + '/enterprise/group/',
  //  企业权益列表查询
  GET_BENEFIT: enterprise + '/enterprise/benefit/pageList',
  //  获取所有权益
  GET_LIST_BENEFIT: enterprise + '/benefit/listBenefit',
  //  删除企业权益
  DEL_BENEFIT: enterprise + '/enterprise/benefit/deleteEnterpriseBenefit',
  //  创建企业权益
  ADD_BENEFIT: enterprise + '/enterprise/benefit/createEnterpriseBenefit',
  // 企业入驻入驻空间三级联动
  ASSET_FIND_ALL_SPACE: enterprise + '/assets/enterprise/findAllSpace ',
  // 企业入驻编辑企业
  ASSET_EDIT_ENTER: enterprise + '/assets/enterprise/update',
  // 企业入驻企业列表获取接口
  ASSET_ENTERPRIS_ENAMEPLANB: enterprise + '/assets/enterprise/findEnterpriseList',
  // 企业入驻查询企业详情
  ASSET_SENTERPRISE: enterprise + '/assets/enterprise',
  // 企业列表=空间是否全部退租
  SETTLE_QUIT_LABEL: enterprise + '/enterprise/settle/quit/label',
  // 企业列表-退租
  THROW_LEASE: enterprise + '/iyou/enterprise/settled/settle/quit',
  // 企业名称 联系人 电话
  enterpriseName: enterprise + '/iyou/enterprise/addStaffFindEnterpriseInfo',
  // 企业列表获取接口
  enterpriseNamePlanB: enterprise + '/iyou/enterprise/list',
  // 入驻空间三级联动
  FIND_ALL_SPACE: enterprise + '/enterprise/settled_project/down_box',
  GET_ENTER_DETAIL_BY_ID: enterprise + '/iyou/enterprise/detail',
  // 企业列表-入驻列表
  THROW_LEASE_LIST: enterprise + '/iyou/enterprise/settled/surrender_tenancy',
  // 获取当前企业下的员工列表
  GET_CURRENT_ENTER_STAFF_LIST: enterprise + '/staff/v2/findStaffByEntId',
  // 获取员工详情
  GET_STAFF_DETAIL: enterprise + '/iyou/staff/updateStaffEnterpriseRole',
  // 编辑企业
  EDIT_ENTER: enterprise + '/enterprise/update', 
  // 添加企业
  ADD_ENTER: enterprise + '/enterprise/add',
  // 删除企业
  DELETE_ENTER: enterprise + '/enterprise/deleteEnterprise',
  // 职位管理——列表
  GET_POSITION_OPTIONS: enterprise + '/web/position/positions',
  GET_POSITION_LIST: enterprise + '/web/position/list',
  GET_POSITION_ADD: enterprise + '/web/position/add',
  GET_POSITION_EDIT:enterprise + '/web/position/update',
  GET_POSITION_DISABLED:enterprise + '/web/position/disabled',
  // 轮播图顺序调整保存
  BANNER_MOVE: platform + '/banner_info/bannerMove',
   // 轮播图修改
   LABEL_ASNCSACDKJDS: platform + '/banner_info/updateBanner',
}

// 获取企业类型
export function getEnterpriseType() {
  return fetchApi('/api/enterprise/iyou/enterprise/enterprise-type', {}, 'get')
}


// 获取融资类型
export function getTradeInfo() {
  return fetchApi(
    URL.GET_TRADE_INFO, {}, 'get'
  )
}

// 获取可入住项目
export function getAllowJoinProject() {
  return http({
    url: URL.GET_ALLOW_JOIN_PROJECT,
    method: 'get'
  })
}

// 获取企服专业列表
export function getCommissionerByProjectId(projectId) {
  return http({
    url: URL.GET_COMMISSIONER_LIST_BY_PROJECT_ID,
    method: 'get',
    params: {
      'projectId': projectId
    }
  })
}

// 获取角色列表
export function getRoleList() {
  return http({
    url: URL.GET_ROLE_LIST,
    method: 'get',
    params: {
      'roleType': '2'
    }
  })
}

// 添加员工
export function addStaff(data) {
  return http({
    url: URL.ADD_STAFF,
    method: 'post',
    data: {
      'staff_name': data.staff_name,
      'staff_phone': data.staff_phone,
      'card_number': data.card_number,
      'enterprise_roles': data.enterprise_roles,
      'join_source': 2,
      'ent_id': data.ent_id
    }
  })
}
// 获取删除列表
export function getdeletelist(page, size, id) {
  return http({
    url: URL.GET_DELECT_LIST, // '/api/enterprise/staff/selectCompanyStaffInfo',
    method: 'post',
    data: {
      page_index: page,
      page_size: size,
      source_type: 1
    }
  })
}
// 下载导入员工模板
export function templateDeleteExport(data) {
  return http({
    url: URL.TEMPLATE_DELETE_EXPORT,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    method: 'post',
    data: data
  })
}
// 下载删除结果
export function downUploadResult(data) {
  return http({
    url: URL.GET_DOWNLOAD_RESULE,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    method: 'post',
    data: data
  })
}
// 批量删除
export function batchAll(enterpriseId, file) {
  return http({
    url: URL.BATCH_DELETE + '/' + enterpriseId + '/1',
    headers: {
      'Content-Type': 'multipart/form-data;charset=utf-8'
    },
    method: 'post',
    data: file
  })
}

// 编辑员工
export function editStaff(data, staffId) {
  return http({
    url: URL.EDIT_STAFF,
    method: 'post',
    data: {
      'staff_name': data.staff_name,
      'staff_phone': data.staff_phone,
      'card_number': data.card_number,
      'enterprise_roles': data.enterprise_roles,
      'staff_id': staffId,
      'join_source': 2
    }
  })
}

// 删除员工/移除员工
export function deleteStaff(data) {
  return http({
    url: URL.DELETE_STAFF,
    method: 'get',
    params: {
      'id': data.id,
      'enterpriseId': data.ent_id
    }
  })
}

// 查询员工列表
export function getStaffList(data, page, size) {
  return http({
    url: URL.GET_STAFF_LIST,
    method: 'post',
    data: {
      'enterprise_name': data.enterprise_name,
      'staff_name': data.staff_name,
      'staff_phone': data.staff_phone,
      'enterprise_role': data.enterprise_role,
      'page': page,
      'size': size,
      'role_id':store.state.common.currentMenuRoleId
    }

  })
}

// 获取角色列表
export function getStaffDetail(staffId) {
  return http({
    url: URL.GET_STAFF_DETAIL,
    method: 'get',
    params: {
      'staffId': staffId
    }
  })
}

// 企业管理
// 企业名称 联系人 电话
export function getEnterpriseName(data, page, size) {
  return http({
    url: URL.enterpriseName,
    method: 'post',
    data: {
      'enterprise_name': data.enterprise_name,
      'fellow_phone_name': data.fellow_phone_name,
      'project_id': data.project_id,
      'page': page,
      'size': size,
      'role_id':store.state.common.currentMenuRoleId
    }
  })
}

// 企业列表获取
export function getEnterpriseNamePlanB(data, page, size) {
  return fetchApi(URL.enterpriseNamePlanB, {
    'enterprise_name': data.enterprise_name,
    'fellow_phone_name': data.fellow_phone_name,
    'project_id': data.project_id.length ? data.project_id[data.project_id.length - 1] : '',
    'enterprise_settle_status': data.enterprise_settle_status,
    'enterprise_type': data.enterprise_type,
    'us_code': data.us_code,
    'page': page,
    'size': size,
    'industry_name': data.industry_name,
    'role_id':store.state.common.currentMenuRoleId,
    'industry_id_list': data.industry_id_list
  }, 'post', 'data')
}

// 获取当前企业下的员工
export function getCurrentEnterStaffList(data, page, size) {
  const obj = {
    enterpriseId: data.id,
    staffName: data.staffName,
    staffPhone: data.staffPhone,
    roleId: data.staffRoleId,
    page,
    size

  }
  
  return http({
    url: URL.GET_CURRENT_ENTER_STAFF_LIST,
    method: 'get',
    params: {
      ...obj
    }
  })
}

// 添加企业
export function addEnter(data) {
  return http({
    url: URL.ADD_ENTER,
    method: 'post',
    data: {
      'enterprise_name': data.enterprise_name,
      'enterprise_type': data.enterprise_type,
      'us_code': data.us_code,
      'enterprise_desc': data.enterprise_desc,
      'projects': data.projects,
      'group_enterprise_id_list': data.group_enterprise_id_list,
      'is_in_incubator': data.is_in_incubator,
      'industry_id': data.industry_id
    }
  })
}

// 编辑企业
export function editEnter(data, id) {
  return http({
    url: URL.EDIT_ENTER,
    method: 'put',
    data: {
      'id': id,
      'enterprise_name': data.enterprise_name,
      'enterprise_type': data.enterprise_type,
      'us_code': data.us_code,
      'enterprise_desc': data.enterprise_desc,
      'projects': data.projects,
      'group_enterprise_id_list': data.group_enterprise_id_list,
      'is_in_incubator': data.is_in_incubator,
      'industry_id': data.industry_id
    }
  })
}

// 删除
export function deleteEnter(id) {
  return http({
    url: URL.DELETE_ENTER,
    method: 'delete',
    params: {
      'entId': id
    }
  })
}

// 查询企业详情
export function getEnterDetailById(id) {
  return http({
    url: `${URL.GET_ENTER_DETAIL_BY_ID}`,
    method: 'get',
    params: {
      enterpriseId:id,
      roleId:store.state.common.currentMenuRoleId
    }
  })
}
/**
* @description 根据企业id查询企业服务豆信息
* @author cxd
* @param
* @since 2021-08-27 16:04:50
*/
export function getEnterpriseServiveBeansById(data) {
  return http({
    url: URL.GET_ENTERPRISE_SERVICE_BEANS_BY_ID,
    method: 'GET',
    params: {
      enterpriseId: id
    }
  })
}

// 获取所有供应商列表
export function getAllSupplierList() {
  return http({
    url: URL.GET_ALL_SUPPLIER_LIST,
    method: 'get'
  })
}
//  -首页服务管理-新建服务
export function saveEnterpriseService(data) {
  return http({
    url: URL.LABEL_ADDTRVEP,
    method: 'post',
    data
  })
}
// 首页服务管理-服务列表
export function pageHomeService(page, size, name) {
  return http({
    url: URL.LABEL_JPQMSNSROE,
    method: 'get',
    params: {
      page,
      size,
      name
    }
  })
}
// PC-首页服务管理-查询
export function getHomeServiceInfo(id) {
  return http({
    url: URL.LABEL_BXYERUOSP + '/' + id,
    method: 'get'
  })
}
// 首页服务管理-新建服务-项目列表
export function fetch() {
  return http({
    url: URL.LABEL_KOPSERBTY,
    method: 'get'
  })
}
// 查询轮播图列表
export function bannerinfoList(limit, current, data) {
  return http({
    url: URL.LABEL_UDKSOPEWK + '/' + limit + '/' + current,
    method: 'post',
    data
  })
}
// 添加轮播图
export function insertBanner(data) {
  return http({
    url: URL.LABEL_DHDCTEWPS,
    method: 'post',
    data
  })
}
// 根据轮播图id查询轮播图详情
export function getbannerId(bannerId) {
  return http({
    url: URL.LABEL_UWMSJOPAUR + '/' + bannerId,
    method: 'get'
  })
}
// 删除、批量删除轮播图信息
export function bannerinfo(data) {
  return http({
    url: URL.LABEL_UWANCJROPAQ,
    method: 'DELETE',
    data
  })
}
// 适用项目
export function CanProject() {
  return http({
    url: URL.SHOP_AGHDTERYU,
    method: 'get'
  })
}
// 查询位置信息（位置信息(1：web首页 2：移动端H5首页顶部 3：移动端H5首页中部 4：移动端H5政策申报)）
export function infoplace() {
  return http({
    url: URL.LABEL_USHFGEPSSNYU,
    method: 'get'
  })
}
// 修改服务列表
export function editHomeService(data) {
  return http({
    url: URL.LABEL_PBZMSJERW,
    method: 'post',
    data
  })
}
// 轮播图修改
export function updateBanner(data) {
  return http({
    url: URL.LABEL_ASNCSACDKJDS,
    method: 'PUT',
    data
  })
}
// 企业列表  入驻空间三级联动
export function findAllSpace() {
  return http({
    url: URL.FIND_ALL_SPACE,
    method: 'get'
  })
}
// 轮播图排序保存
export function bannerMove(data) {
  return http({
    url: URL.BANNER_MOVE,
    method: 'post',
    data
  })
}

// 企业管理-职位管理——所有职位集合
export function positionOptions() {
  return http({
    url: URL.GET_POSITION_OPTIONS,
    method: 'get'
  })
}

// 企业管理-职位管理——列表
export function getPositionList(params) {
  return http({
    url: URL.GET_POSITION_LIST,
    method: 'get',
    params
  })
}

// 企业管理-职位管理——新增
export function addPosition(data) {
  return http({
    url: URL.GET_POSITION_ADD,
    method: 'POST',
    data
  })
}

// 企业管理-职位管理——启用/禁用
export function disablePosition(data) {
  return http({
    url: URL.GET_POSITION_DISABLED,
    method: 'PUT',
    data
  })
}
// 企业管理-职位管理——编辑
export function editPosition(data) {
  return http({
    url: URL.GET_POSITION_EDIT,
    method: 'PUT',
    data
  })
}
/**
 * @description: 企业列表-入驻信息
 * @param {*} data enterpriseId:企业ID page:当前页 size:当前条数
 * @return {*}
 */
export function throwLeaseList(data) {
  return http({
    url: URL.THROW_LEASE_LIST,
    method: 'post',
    data: {
      ...data,
      'role_id': store.state.common.currentMenuRoleId
    }
  })
}
/**
 * @description: 企业退租
 * @param {*} data
 * @return {*}
 */
export function throwLease(data) {
  return http({
    url: URL.THROW_LEASE,
    method: 'POST',
    data
  })
}
/**
 * @description: 企业列表-入驻状态
 * @param {*}
 * @return {*}
 */
export function enterpriseSettleList() {
  return fetchApi(URL.ENTERPRISE_SETTLE_LIST, {}, 'get'
  )
}
/**
 * @description: 企业退租-是否全部退租
 * @param {*} data
 * @return {*}
 */
export function settleQuitLabel(data) {
  return http({
    url: URL.SETTLE_QUIT_LABEL,
    method: 'POST',
    data
  })
}

// 企业入驻查询企业详情
export function enterpriseSettlementDetail(id) {
  return http({
    url: `${URL.ASSET_SENTERPRISE}/${id}`,
    method: 'get',
    data: {}
  })
}
// 企业入驻企业列表获取
export function enterprisesettlementenameplanb(data, page, size) {
  return http({
    url: URL.ASSET_ENTERPRIS_ENAMEPLANB,
    method: 'post',
    data: {
      'enterprise_name': data.enterprise_name,
      'fellow_phone_name': data.fellow_phone_name,
      'project_id': data.project_id,
      'enterprise_settle_status': data.enterprise_settle_status,
      'enterprise_type': data.enterprise_type,
      'page': page,
      'size': size
    }
  })
}
// 企业入驻编辑企业
export function enterpriseSettlementeditEnter(data, id) {
  return http({
    url: URL.ASSET_EDIT_ENTER,
    method: 'put',
    data: {
      'id': id,
      'enterprise_name': data.enterprise_name,
      'enterprise_type': data.enterprise_type,
      'us_code': data.us_code,
      'enterprise_desc': data.enterprise_desc,
      'projects': data.projects
    }
  })
}
// 企业入驻入驻空间三级联动
export function enterpriseSettlementfindAllSpace() {
  return http({
    url: URL.ASSET_FIND_ALL_SPACE,
    method: 'get'
  })
}
//  获取所有权益下拉
export function getlistBenefit() {
  return http({
    url: URL.GET_LIST_BENEFIT,
    method: 'get'
  })
}
//  添加权益
export function addBenefit(data) {
  return http({
    url: URL.ADD_BENEFIT,
    method: 'POST',
    data: {
      enterprise_id: data.enterpriseId,
      benefit_id: data.benefitId
    }
  })
}
//  删除权益
export function delBenefit(id) {
  return http({
    url: URL.DEL_BENEFIT,
    method: 'POST',
    params: {
      enterpriseBenefitId: id
    }
  })
}
//  权益列表
export function getBenefitList(data) {
  console.log(data)
  return http({
    url: URL.GET_BENEFIT,
    method: 'POST',
    data
  })
}
//  获取关联企业详情
export function getGroup(ID) {
  return http({
    url: URL.GET_GROUP + ID,
    method: 'get'
  })
}
//  获取关联企业下拉
export function getGroupDown(enterpriseName, id) {
  return http({
    url: URL.GET_GROUPDROPDOWN,
    method: 'get',
    params: {
      enterpriseName: enterpriseName,
      enterpriseId: id
    }
  })
}
export function addGroup(data) {
  return http({
    url: URL.ADD_GROUPADD,
    method: 'POST',
    data
  })
}
// 绑定企查查企业列表
export function getBindingList(id, page, size) {
  console.log(id, page, size)
  return http({
    url: URL.POST_GETBINDINGLIST,
    method: 'POST',
    data: {
      us_code: id,
      'page': page,
      'size': size
    }

  })
}
// 添加绑定企查查企业信息
export function addBinding(data) {
  return http({
    url: URL.POST_ADDBINDING,
    method: 'POST',
    data
  })
}
// 修改绑定企查查企业 启用/禁用
export function updateBindingState(data) {
  return http({
    url: URL.POST_UPADTEBINDINGSTATE,
    method: 'POST',
    data
  })
}
// 手动触发单条更新数据
export function updateInfo(data) {
  return http({
    url: URL.POST_UPADTEINFO,
    method: 'POST',
    data
  })
}
// 手动触发批量更新数据
export function updateInfos(data) {
  return http({
    url: URL.GET_UPADTEINFOS,
    method: 'get'
  })
}
//  查询企查查同步企业其他信息
export function getOtherInfoDetail(id) {
  return fetchApi(URL.GET_OTHERINFODETAIL + '/' + id, {}, 'get')
}
// 企查查错误UsCode列表
export function downErrorUscode(id) {
  console.log(id)
  return http({
    url: URL.POST_DOWNERROREXCEL,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    method: 'post',
    params: {
      id: id
    }
  })
}
// 企查查错误UsCode列表
export function getErrorUsCodeList(data) {
  return fetchApi(URL.POST_GETERRORUSCODELIST, data, 'post', 'data')
}
// 上传Excel文件批量导入企查查同步企业UsCode
export function importUsCode(file) {
  return http({
    url: URL.POST_IMPORTENTUSCODE,
    headers: {
      'Content-Type': 'multipart/form-data;charset=utf-8'
    },
    method: 'post',
    data: file
  })
}
// 删除分类
export function deleteIndustryTree(id) {
  return fetchApi(URL.DELETEINDUSTRYLIST + id,
    {}, 'DELETE'
  )
}
// 获取项目
export function getProject() {
  return fetchApi(URL.AUTH_PROJECT_LIST, {}, 'get')
}
export function getShunyiAllProject(data) {
  return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST, data, 'post', 'data', true);
}

// 获取行业分类树形列表
export function getindustryTree(id) {
  return fetchApi(
    URL.GETINDUSTRYLIST, id, 'get'
  )
}
export function getIndustryTreeInfo(id) {
  return fetchApi(URL.GETALLINDUSTRYINFO + id, '', 'get'
  )
}
// 修改分类
export function updateIndustryTree(data) {
  return fetchApi(URL.UPDATEINDUSTRYLIST, data, 'post', 'data'
  )
}
// 添加分类
export function addIndustryTree(data) {
  return fetchApi(URL.ADDINDUSTRYLIST, data, 'post', 'data')
}
// 添加分类
export function getAllIndustryTree(data) {
  return fetchApi(URL.GETALLINDUSTRYLIST, data, 'post', 'data'
  )
}
